import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASE_URL, FETCH_FLOW_BLOCKS_BY_ID } from '../utils/constants/constants';
import * as Sentry from '@sentry/react';

const useFetchFlowBlocks = ( testId ) => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );
    const isFirstMount = useRef( true );
    const FETCH_DATA = `${ BASE_URL }${ FETCH_FLOW_BLOCKS_BY_ID }`

    useEffect( () => {
        if ( isFirstMount.current ) {
            setIsLoading( true );

            const FetchFlowBlocks = async ( testId ) => {
                try {
                    const response = await axios.get( `${ FETCH_DATA }${ testId }/` );
                    setResponse( response.data );
                } catch ( err ) {
                    setError( err );
                    Sentry.captureException( err );
                } finally {
                    setIsLoading( false );
                }
            };
            FetchFlowBlocks( testId );
            isFirstMount.current = false
        }
    }, [] );

    return { response, error, isLoading };
};

export default useFetchFlowBlocks;