import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
    BASE_URL, CREATE_USER_ASSESSMENT_RESPONSE
} from '../utils/constants/constants';
import * as Sentry from '@sentry/react';

const useCreateUserAssessmentResponse = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );
    const [isCalled, setIsCalled] = useState( false );

    const [queryParams, setQueryParams] = useState( {} ); // State to hold query parameters

    useEffect( () => {
        // Function to parse query parameters from URL
        const getQueryParamsFromURL = () => {
            const searchParams = new URLSearchParams( window.location.search );
            const params = {};
            for ( const [key, value] of searchParams ) {
                params[key] = value;
            }
            return params;
        };

        // Get query parameters from URL and update state
        const params = getQueryParamsFromURL();
        setQueryParams( params );
    }, [] );

    const CreateUserAssessmentResponse = async ( userId, formId, iscompleted, testId ) => {
        if ( isCalled ) {
            console.warn( "CreateUserAssessmentResponse has already been called." );
            return;
        }

        setIsLoading( true );
        setIsCalled( true );

        const requestBody = {
            form_id: formId,
            is_completed: Number( iscompleted ),
            test_id: testId,
        };

        if ( userId > 0 ) {
            requestBody.student_id = userId;
        }

        const API_URL = `${ BASE_URL }${ CREATE_USER_ASSESSMENT_RESPONSE }?${ new URLSearchParams( queryParams ) }`;

        try {
            const res = await axios.post( API_URL, requestBody );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
            Sentry.captureException( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, CreateUserAssessmentResponse };
};

export default useCreateUserAssessmentResponse;